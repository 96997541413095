@include media-x-large {

	#left {
	    width: 83.33333%;
	}
	#right {
	    width: 16.66667%;
	}

}
