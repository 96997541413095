$liferay-control-z-index: 1034;
$liferay-top-control-menu-height: 65px;

$content-max-width: 1300px;

$theme-header-z-index: $liferay-control-z-index + 5;
$theme-header-submenus-z-index: $theme-header-z-index;
$theme-header-height: 60px;
$theme-portlet-topper-z-index: $theme-header-z-index - 1;
$theme-portlet-hover-topper-z-index: $theme-header-z-index;
$theme-header-secondary-navigation-height: 60px;
$theme-mobile-navigation-z-index: $theme-header-z-index;
$theme-footer-height: 40px;

/* Colors */

$body-color: black;
$page-color: white;
$page-background-color: black;
$page-border-color: #ccc;

$about-color: #ff921e;
$about-accent-color: #be640b;
$about-background-color: $about-color;
$about-border-color: $about-color;

$investors-color: #662D91;
$investors-accent-color: #430F70;
$investors-clear-color: #BB8BD8;
$investors-background-color: $investors-color;
$investors-border-color: $investors-color;

$products-and-services-color: #3FA9F5;
$products-and-services-accent-color: #003876;
$products-and-services-accent-color-darker: #0B222F;

$sustainability-color: #79c843;
$sustainability-accent-color: #12881E;
$sustainability-background-color: $sustainability-accent-color;
$sustainability-border-color: $sustainability-color;

$suppliers-color: #FBCF62;
$suppliers-accent-color: #BC8600;
$suppliers-background-color: $suppliers-color;
$suppliers-border-color: $suppliers-color;

$careers-color: #BF3A2B;
$careers-accent-color: #820C00;
$careers-background-color: $careers-color;
$careers-border-color: $careers-color;

$sitemap-color: #BF3A2B;
$sitemap-accent-color: #820C00;
$sitemap-background-color: $sitemap-color;
$sitemap-border-color: $sitemap-color;

$life-color: #BF3A2B;
$life-accent-color: #820C00;
$life-background-color: $life-color;
$life-border-color: $life-color;

$microsite-color: #27AE60;
$microsite-background-color: $microsite-color;
$microsite-border-color: $microsite-color;

$key-company-color: #662D91;
$key-company-background-color: $key-company-color;
$key-company-border-color: $key-company-color;
$key-company-accent-color: #430F70;
$key-company-clear-color: #BB8BD8;

$media-color: #c8ccd2;
$media-accent-color: #5a5d66;
$media-link-color: #628fb6;
$media-background-color: $media-color;
$media-border-color: $media-color;

$blue-box-color: #103185;
$blue-box-secondary-color: #06215E;

$gray-box-color: #1e1e1e;
$gray-box-secondary-color: #121212;

$red-box-color: #BF3A2B;
$red-box-secondary-color: #820C00;

$gray-blue-box-color: #48647c;
$gray-blue-secondary-color: #2c3e50;

/* Fonts */

$hero-font-size: 58px;
$h1-font-size: 40pt;
$h2-font-size: 28pt;
$h3-font-size: 20pt;
$h4-font-size: 18pt;
$h5-font-size: 10pt;
$body-font-size: 14pt;
$image-subtitle-font-size: 13px;
$feature-description-font-size: 15px;