.absolute-fill-parent {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.full-height {
	height: 100%;
}

.half-height {
	height: 50%;
}

.full-width {
	width: 100%;
}